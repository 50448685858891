import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {userInteractionProps, userModifiedProps} from "@core/forms/model.directive";
import {
  DevisEtapePeriodeCouvertureState
} from "@way/app/devis/etape-periode-couverture/store/etape-periode-couverture.state";
import {ReferentielProduitMotifSejourResource} from "@core/backend/models/referentiel";

export const periodeCouvertureActions = createActionGroup({
  source: '[devis/periode-couverture]',
  events: {
    'utilisateurAInteragisAvecFormulaire': userInteractionProps<DevisEtapePeriodeCouvertureState>(),
    'utilisateurAModifieFormulaire': userModifiedProps<DevisEtapePeriodeCouvertureState>(),
    'utilisateurACliqueSurBoutonDureePlus': props<{
      motifsSelectionnables: ReferentielProduitMotifSejourResource[],
      codeMotifSelectionnee: string
    }>(),
    'utilisateurACliqueSurBoutonDureeMoins': props<{
      motifsSelectionnables: ReferentielProduitMotifSejourResource[],
      codeMotifSelectionnee: string
    }>(),
    'utilisateurACliqueSurEtapeSuivante': emptyProps(),
  }
})
