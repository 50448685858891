import {NgModule} from '@angular/core';
import {MatLuxonDateModule} from '@angular/material-luxon-adapter';
import {ModelDirective} from './forms/model.directive';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LocaleInterceptor} from './services/locale-interceptor.service';
import {DebuggerInterceptor} from "@core/services/debugger-interceptor.service";
import {MshDatePipe} from "@core/pipes/msh-date.pipe";
import {DatePipe} from "@angular/common";

@NgModule({
  declarations: [
  ],
  imports: [
    ModelDirective,
    MatLuxonDateModule,
    HttpClientModule,
    MshDatePipe,
  ],
  exports: [
    ModelDirective,
    MshDatePipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DebuggerInterceptor,
      multi: true
    },
    DatePipe,
  ]
})
export class CoreModule { }
