import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpInterceptorFn} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class DebuggerInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return debuggerInterceptor(request, next.handle);
  }
}

export const debuggerInterceptor : HttpInterceptorFn = (request, next)=> {

  if(request.headers.has('AvoidDebuggerInterceptor')){
    // Clone the request and remove the 'AvoidDebuggerInterceptor' header
    const clonedRequest = request.clone();
    clonedRequest.headers.delete('AvoidDebuggerInterceptor');

    return next(clonedRequest);
  }

  if(runtimeEnv.APP_ENV === 'dev'){
    //Append XDEBUG_SESSION_START=1 to the query
    const modifiedUrl = request.urlWithParams.includes('?')
      ? `${request.urlWithParams}&XDEBUG_SESSION_START=1`
      : `${request.urlWithParams}?XDEBUG_SESSION_START=1`;

    const authReq = request.clone({
      url:modifiedUrl
    });

    return next(authReq)
  }
  return next(request)
}
