import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const destinationActions = createActionGroup({
  source: '[devis/destination]',
  events: {
    'utilisateurATouchePaysDomicile': emptyProps(),
    'utilisateurASelectionnePaysDomicile': props<{ codePaysDomicile: string }>(),
    'utilisateurATouchePaysDestination': emptyProps(),
    'utilisateurASelectionnePaysDestination': props<{ codePaysDestination: string }>(),
    'utilisateurACliqueSurEtapeSuivante': props<{ prochaineEtape: 'accesACeam' | 'accesAMonTarif' }>(),
  }
})
