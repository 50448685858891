import {JsonValueOf} from "@core/helpers/json-manipulation.helpers";
import {DevisResource} from "@core/backend/models/devis";

export interface DevisMonTarifFeatureState{
  monTarif: DevisMonTarifState;
}

export interface DevisMonTarifState{
  devis: JsonValueOf<DevisResource> | null;
}

export class StateFactory{

  public static createInitialDevisState():DevisMonTarifFeatureState{
    return {
      monTarif:{
        devis: null
      },
    }
  }
}


