import {Injectable, LOCALE_ID, inject} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpInterceptorFn} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return localeInterceptor(request, next.handle);
  }

}

export const localeInterceptor : HttpInterceptorFn = (request, next)=> {
  const locale:string = inject(LOCALE_ID);
  const authReq = request.clone({
    setHeaders: {
      'Accept-Language':locale
    }
  });
  return next(authReq);
}

