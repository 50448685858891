import {createActionGroup, emptyProps} from "@ngrx/store";

export const ceamActions = createActionGroup({
  source: '[devis/ceam]',
  events: {
    'utilisateurACliqueSurOui': emptyProps(),
    'utilisateurACliqueSurNon': emptyProps(),
    'utilisateurACliqueSurEtapeSuivante': emptyProps(),
  }
})
